import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import './index.scss';
import { BURNETT_AUTH0_DOMAIN, BURNETT_AUTH0_CLIENT_ID } from './config';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
    domain={BURNETT_AUTH0_DOMAIN}
    clientId={BURNETT_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}
    cookieDomain={window.location.hostname === "localhost" ? "localhost" : ".longjump.ai"}
  >
    <App />
  </Auth0Provider>
);
