import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import MuxPlayer from '@mux/mux-player-react';
import axios from 'axios';
import { toast } from "react-toastify";
import UserCard from '../../components/generic/userCard';
import Popup from '../../components/generic/popup';
import Button from '../../components/generic/button';
import BackButton from '../../components/generic/backButton';
import MultiSelect from '../../components/generic/multiSelect';
import { BURNETT_API_ENDPOINT } from '../../config';
import './submission.scss';

function Submission() {
  const navigate = useNavigate();
  const { competitionId, submissionId } = useParams();
  const { user, getIdTokenClaims } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [isBoostLoading, setIsBoostLoading] = useState(false);
  const [boostMetricsLoading, setBoostMetricsLoading] = useState(false);
  const [boostPopupActive, setBoostPopupActive] = useState(false);
  const [error, setError] = useState(null);
  const [userSessionScope, setUserSessionScope] = useState("");
  const [competitionName, setCompetitionName] = useState("");
  const [submissionName, setSubmissionName] = useState("");
  const [submissionDesc, setSubmissionDesc] = useState("");
  const [submissionStatus, setSubmissionStatus] = useState("");
  const [submissionCreatorId, setSubmissionCreatorId] = useState("");
  const [activeUserEmail, setActiveUserEmail] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [videoUrl, setVideoUrl] = useState(null);
  const [muxPlaybackId, setMuxPlaybackId] = useState(null);
  const [teamList, setTeamList] = useState([]);
  const [linkList, setLinkList] = useState([]);
  const [submissionBoostAmount, setSubmissionBoostAmount] = useState(0);
  const [competitionUsers, setCompetitionUsers] = useState([]);
  const [isSubmissionAdmin, setIsSubmissionAdmin] = useState(false);

  const [totalBoostPoints, setTotalBoostPoints] = useState(0);
  const [userBoostPointsRemaining, setUserBoostPointsRemaining] = useState(0);

  const [activeCategorySelections, setActiveCategorySelections] = useState([]);
  const [competitionSubmission, setCompetitionSubmission] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState([
    'Technology',
    'Finance',
    'Healthcare',
    'Education',
    'Energy',
    'Manufacturing',
    'Retail',
    'Ecommerce',
    'Transportation',
    'Hospitality & Leisure',
    'Fashion & Apparel',
    'Beauty & Personal Care',
    'Construction',
    'Media & Entertainment',
    'Agriculture',
    'Food & Beverage',
    'Biotech',
    'Security',
    'Professional Services'
  ]);

  useEffect(() => {
    if (!user || !teamList) return;
    if (teamList.includes(user.email)) {
      setIsSubmissionAdmin(true);
    }
  }, [user, teamList]);
  

  const formatDate = (dateString) => {
    return moment(dateString).format('MMMM Do, YYYY');
  };

  const findUserTypeByEmail = (users = [], email) => {
    for (let i = 0; i < users.length; i++) {
      if (users[i].email === email) {
        return users[i].type;
      }
    }
    return null;
  }

  const fetchCompetitionData = async () => {
    setLoading(true);
    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;
      const response = await axios.get(`${BURNETT_API_ENDPOINT}/get-competition?competition_id=${competitionId}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      const data = response.data;
      const userSessionEmail = user.email;
      const permissionScope = findUserTypeByEmail(data.users, userSessionEmail);

      setCompetitionUsers(data.users);

      setUserSessionScope(permissionScope);
      setActiveUserEmail(user.email);
      setCompetitionName(data.competition_name);
      fetchSubmissionData();

    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const fetchSubmissionData = async () => {
    setLoading(true);
    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;
      const response = await axios.get(`${BURNETT_API_ENDPOINT}/get-published-competition-submissions?competition_id=${competitionId}&submission_id=${submissionId}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      const data = response.data;
      const { data: submissionData } = data;

      if (submissionData && submissionData.length > 0) setCompetitionSubmission(submissionData[0]);
      setLoading(false);

    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const fetchUserBoostMetrics = async () => {
    setBoostMetricsLoading(true);
    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;
      const response = await axios.get(`${BURNETT_API_ENDPOINT}/get-user-boost-metrics?competition_id=${competitionId}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      const userBoostMetrics = response.data;

      const { allocations, max_allocation, total_spend } = userBoostMetrics;

      const pointsRemaining = max_allocation - total_spend;

      setUserBoostPointsRemaining(`${pointsRemaining} of ${max_allocation}`);

      for (const allocation of allocations) {
        if (allocation.submission_id === submissionId) {
          setSubmissionBoostAmount(allocation.allocated_boost_points);
        }
      }

      setBoostMetricsLoading(false);

    } catch (err) {
      setBoostMetricsLoading(false);
    }
  };

  useEffect(() => {
    if (!competitionSubmission) return;

    const {
      submission_name,
      submission_desc,
      status,
      updated_at,
      created_at,
      user_id,
      submission_categories = [],
      submission_team = [],
      submission_links = [],
      video_url,
      mux_playback_id,
      total_points
    } = competitionSubmission;

    setSubmissionName(submission_name);
    setSubmissionDesc(submission_desc);
    setSubmissionStatus(status);
    setSubmissionCreatorId(user_id);
    setTeamList(submission_team);
    setLinkList(submission_links);
    setActiveCategorySelections(submission_categories);
    setVideoUrl(video_url);
    setTotalBoostPoints(total_points);
    setUpdatedAt(updated_at);
    setMuxPlaybackId(mux_playback_id);

  }, [competitionSubmission]);

  useEffect(() => {
    fetchCompetitionData();
  }, [competitionId, getIdTokenClaims]);

  useEffect(() => {
    if (!boostPopupActive) return;
    fetchUserBoostMetrics();
  }, [boostPopupActive]);

  const toggleBoostClick = async () => {
    if (isBoostLoading) return;
    setBoostPopupActive(!boostPopupActive);
  }

  const confirmBoost = async () => {
    if (isBoostLoading || boostMetricsLoading) return;
    setIsBoostLoading(true);

    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;

      const boostParams = {
        competition_id: competitionId,
        submission_id: submissionId,
        boost_points: submissionBoostAmount
      }

      const response = await axios.post(`${BURNETT_API_ENDPOINT}/create-submission-boost`, boostParams, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      toast.success('Submission Boosted!');
      setIsBoostLoading(false);
      fetchSubmissionData();
      setSubmissionBoostAmount("-")
      toggleBoostClick();

    } catch (err) {
      setError(err.message);
      setIsBoostLoading(false);
      toast.error('Error boosting submission');
    }
  }

  const handleBoostInputChange = (e) => {
    setSubmissionBoostAmount(e.target.value);
  };

  const handleViewCompetitionClick = () => {
    navigate(`/competition/${competitionId}`);
  };

  const onClickEditSubmission = () => {
    navigate(`/competition/${competitionId}/manage-submission/${submissionId}`);
  }

  return (
    <div className="submission-container">
      {boostPopupActive && (
        <Popup isOpen={boostPopupActive} onClose={toggleBoostClick}>
          <h2>Boost Submission</h2>
          <p><b>{userBoostPointsRemaining}</b> points remaining for this competition</p>
          <div className="boost-popup-content">
            <div className="boost-popup-icon">
              <span>Number of points to boost</span>
            </div>
            <input
              type="number"
              name="boost"
              value={submissionBoostAmount}
              placeholder="0"
              onChange={(e) => handleBoostInputChange(e)}
              min="0"
            />
            <Button text={isBoostLoading ? "Loading" : "Boost!"} onClick={()=> confirmBoost()} className="primary" />
          </div>
        </Popup>
      )}

      {!loading ? (
        <>
          <BackButton />
          <div className="submission-content">
            <div className="submission-content-section section-left">
              {muxPlaybackId ? (
                <MuxPlayer
                  playbackId={muxPlaybackId}
                  streamType="on-demand"
                />
              ) : <video width="100%" height="100%" controls>
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>}

              <div className="boost-button" onClick={() => toggleBoostClick()}>
                <div className="boost-button-icon" />
                <div className="boost-button-text">Boost</div>
              </div>

            </div>
            <div className="submission-content-section section-right">
              
              <div className="submission-header">
                <h3 onClick={() => handleViewCompetitionClick()}>{competitionName}</h3>
                <div className="total-boost-points">
                  <div className="boost-icon" />
                  <div className="boost-points">{totalBoostPoints} pts</div>
                </div>
                {isSubmissionAdmin ? (
                  <div
                    className="edit-submission-button"
                    onClick={() => onClickEditSubmission()}
                  />
                ) : <></>}
              </div>

              <h1>{submissionName}</h1>
              <span className='updated-at'><b>Last updated:</b> {formatDate(updatedAt)}</span>
              <MultiSelect activeSelections={activeCategorySelections} options={categoryOptions} />

              <div className="submission-desc">
                {submissionDesc}
              </div>

              <div className="submission-links">
                <ul className="link-list">
                  {linkList && linkList.length > 0 && linkList.map((linkItem, i) => {
                    const { key, value } = linkItem;
                    return (
                      <li key={i}>
                        <div className="link-key">{key}:</div>
                        <a href={value} className="link-value" target='_blank'>{value}</a>
                      </li>
                    )
                  })}
                </ul>
              </div>

              <div className="submission-team">
                <b>Teammates</b>
                <ul className="team-list">
                  {teamList && teamList.length > 0 && teamList.map((teamMate) => {
                    const matchingUser = competitionUsers.find(user => user.email === teamMate);
                    const userPathId = matchingUser ? matchingUser.user_id : "no-profile";

                    return <UserCard key={teamMate} competitionId={competitionId} userId={userPathId} userEmail={teamMate} isRow={true} />
                  })}
                </ul>
              </div>

            </div>
          </div>
        </>
      ) : <></>}
    </div>
  )
}

export default Submission;




