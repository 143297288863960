import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { toast } from "react-toastify";
import Button from '../button';
import LoadingDots from '../loadingDots';
import { BURNETT_API_ENDPOINT } from '../../../config';
import './manage-user-type-list.scss';

function ManageUserTypList({ userType, onClose }) {
  const { competitionId } = useParams();
  const { user, getIdTokenClaims } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [competitionName, setCompetitionName] = useState("");
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([{ email: '', role: userType }]);
  const [userSessionEmail, setUserSessionEmail] = useState(null);

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  useEffect(() => {
    const fetchCompetitionData = async () => {
      try {
        const claims = await getIdTokenClaims();
        const idToken = claims.__raw;
        const response = await axios.get(`${BURNETT_API_ENDPOINT}/get-competition?competition_id=${competitionId}`, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json"
          }
        });

        const data = response.data;

        const { users: currentUserData } = data;

        const { email: tempUserSessionEmail } = user;
        setUserSessionEmail(tempUserSessionEmail);

        const updatedUserData = currentUserData
          .filter(userItem => userItem.type === userType)
          .map(userItem => {
            return {
              email: userItem.email,
              role: userItem.type,
            };
          });


        if (updatedUserData && updatedUserData.length > 0) {
          setUsers(updatedUserData);
        } else {
          setUsers([{ email: '', role: userType }]);
        }

        setCompetitionName(data.competition_name);
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchCompetitionData();
  }, [competitionId, getIdTokenClaims]);


  const handleAddUser = () => {
    setUsers([...users, { email: '', role: userType }]);
  };

  const handleDeleteUser = (index) => {
    const newUsers = users.filter((_, i) => i !== index);
    setUsers(newUsers);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newUsers = [...users];
    newUsers[index][name] = value;
    setUsers(newUsers);
  };

  const handleSaveUsers = async () => {
    const filteredUsers = users.filter(user => user.role !== 'OWNER');
  
    if (filteredUsers.some(user => user.email === userSessionEmail)) {
      toast.warning(`Competition Owners & Admins cannot add themselves as: ${userType.toLowerCase()}`);
      return;
    }
  
    const emailSet = new Set();
    const duplicateEmails = [];
  
    for (let user of filteredUsers) {
      if (!validateEmail(user.email)) {
        toast.error('Invalid email found: ' + user.email);
        return;
      }
      if (emailSet.has(user.email)) {
        duplicateEmails.push(user.email);
      } else {
        emailSet.add(user.email);
      }
    }
  
    if (duplicateEmails.length > 0) {
      toast.error('Duplicate emails found: ' + duplicateEmails.join(', ') + '. Please remove duplicates.');
      return;
    }

    const updatedFilteredUserData = filteredUsers.map(userItem => {
      let user = {
        email: userItem.email,
        type: userItem.role,
      };
      
      return user;
    });

    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;

      const params = {
        competition_id: competitionId,
        competition_name: competitionName,
        users: updatedFilteredUserData,
        user_type: userType
      }

      const inviteUsersResult = await axios.post(`${BURNETT_API_ENDPOINT}/update-competition-users`, params, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      toast.success('Competition users updated successfully.');
      onClose();
      
    } catch (error) {
      console.error(error);
      toast.error('Failed to update competition users.');
    }
  };
  
  return (
    <div className="manage-user-type-list-container">
      {error && <p className="error">{error}</p>}
      {isLoading ? (
        <LoadingDots />)
      : (
        <div className="user-list">
          {users.map((user, index) => (
            <div
              key={index}
              className="user-row"
              style={user.disabled ? {pointerEvents: "none", opacity: 0.5} : {}}
            >
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={user.email}
                onChange={(e) => handleInputChange(index, e)}
                autoComplete="off"
                spellCheck="false"
              />
              <Button text="Delete" onClick={()=> handleDeleteUser(index)} className="Secondary" />
            </div>
          ))}
          <div className="footer-buttons">
            <Button text={`Add ${userType.toLowerCase()}`} onClick={()=> handleAddUser()} className="secondary add-user-button" />
            <Button text="Save" onClick={()=> handleSaveUsers()} className="primary save-button" />
          </div>
        </div>)}
    </div>
  );
}

export default ManageUserTypList;
