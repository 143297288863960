import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { toast } from "react-toastify";
import moment from 'moment';
import Popup from '../../components/generic/popup';
import Button from '../../components/generic/button';
import BackButton from '../../components/generic/backButton';
import { BURNETT_API_ENDPOINT } from '../../config';
import './manage-submissions.scss';

function ManageSubmissions() {
  const { competitionId } = useParams();
  const navigate = useNavigate();
  const { user, getIdTokenClaims } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userSessionScope, setUserSessionScope] = useState(null);
  const [competitionName, setCompetitionName] = useState("");
  const [createPopupActive, setCreatePopupActive] = useState(false);
  const [newSubmissionName, setNewSubmissionName] = useState("");
  const [competitionSubmissions, setCompetitionSubmissions] = useState([]);
  const [compIsDraft, setCompIsDraft] = useState(false);
  const [competitionEndDate, setCompetitionEndDate] = useState(null);
  const [competitionDeadlineDate, setCompetitionDeadlineDate] = useState(null);


  const findUserTypeByEmail = (users, email) => {
    for (let i = 0; i < users.length; i++) {
      if (users[i].email === email) {
        return users[i].type;
      }
    }
    return null;
  }

  useEffect(() => {
    const fetchCompetitionData = async () => {
      setLoading(true);
      try {
        const claims = await getIdTokenClaims();
        const idToken = claims.__raw;
        const response = await axios.get(`${BURNETT_API_ENDPOINT}/get-competition?competition_id=${competitionId}`, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json"
          }
        });

        const data = response.data;

        const { status, competition_name, competition_deadline_date, competition_end_date } = data;

        if (status && status === "DRAFT") {
          setCompIsDraft(true);
          return;
        }

        setCompetitionEndDate(competition_end_date);
        setCompetitionDeadlineDate(competition_deadline_date);

        const userSessionEmail = user.email;
        const permissionScope = findUserTypeByEmail(data.users, userSessionEmail);
        setUserSessionScope(permissionScope);
        setCompetitionName(competition_name);

      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchCompetitionData();
  }, [competitionId, getIdTokenClaims]);

  const fetchSubmissionData = async () => {
    setLoading(true);
    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;
      const response = await axios.get(`${BURNETT_API_ENDPOINT}/get-competition-submissions?competition_id=${competitionId}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      const data = response.data;
      const { data: submissionData } = data;
      setCompetitionSubmissions(submissionData);
      setLoading(false);

    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!userSessionScope) return;

    fetchSubmissionData();
  }, [userSessionScope]);


  const handleCreateSubmissionClick = () => {
    const currentTime = moment(); 
    const deadlineDate = competitionDeadlineDate ? moment(competitionDeadlineDate) : moment(competitionEndDate);
    if (currentTime.isAfter(deadlineDate)) {
      toast.error('The deadline has passed. Cannot create a new submission.')
      return;
    }
  
    setCreatePopupActive(!createPopupActive);
    if (createPopupActive) setNewSubmissionName("");
  };
  

  const handleNewSubmissionInputChange = (e) => {
    setNewSubmissionName(e.target.value);
  }

  const handleSaveCreate = async () => {
    if (userSessionScope !== "COMPETITOR") return;
    if (saveLoading) return;

    if (!newSubmissionName || newSubmissionName.length < 3) {
      toast.error('Submission names need to be at least three characters long.')
      return;
    }

    try {
      setSaveLoading(true);
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;

      const params = {
        competition_id: competitionId,
        new_submission_name: newSubmissionName
      }

      const response = await axios.post(`${BURNETT_API_ENDPOINT}/create-competition-submission`, params, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      const data = response.data;

      toast.success('Competition submission successfully created.')
      handleCreateSubmissionClick();
      fetchSubmissionData();

      setSaveLoading(false);
    } catch (err) {
      setError(err.message);
      setSaveLoading(false);
    }
  }

  const handleSubmissionEditClick = (submissionId) => {
    navigate(`/competition/${competitionId}/manage-submission/${submissionId}`);
  }
  
  return (
    <div className="manage-submission-container">
      {createPopupActive && (
        <Popup isOpen={createPopupActive} onClose={handleCreateSubmissionClick}>
          <h2>Create Competition Submission</h2>
          <p>After creation, you will be able to invite team members to collaborate on the submission.</p>
          <input
            type="text"
            name="name"
            placeholder="Submission Team Name"
            value={newSubmissionName}
            onChange={(e) => handleNewSubmissionInputChange(e)}
            autoComplete="off"
            spellCheck="false"
          />
          <Button text={saveLoading ? "Loading" : "Create"} onClick={()=> handleSaveCreate()} className="primary save-button" />
        </Popup>
      )}
      <BackButton />
      {competitionName ? <h3>Manage your competition submissions for:</h3> : <></> }
      <h1>{competitionName}</h1>

      <ul className="competition-submission-list">
        {competitionSubmissions && competitionSubmissions.length > 0 && competitionSubmissions.map((item, i) => {

          const {
            submission_name: submissionName,
            submission_id: submissionId,
            status
          } = item;

          return (
            <li key={submissionId} onClick={()=> handleSubmissionEditClick(submissionId)}>
              {submissionName}
              <div className="submission-status">{status}</div>
            </li>
          )
        })}

      </ul>

      {userSessionScope === "COMPETITOR" ? (
        <Button text="Create Team Submission" onClick={()=> handleCreateSubmissionClick()} className="primary" />
      ) : <></>}

      {compIsDraft ? (
        <>
          <h1>Competition coming soon...</h1>
          <span>This competiton is not yet published</span>
        </>
      ) : <></>}

    </div>
  )
}

export default ManageSubmissions;