import React from 'react';
import UserCard from '../../../components/generic/userCard';
import './list-voting-panel.scss';

function ListVotingPanel({ competitionId, users }) {
  const renderUserCategory = (title, typeCondition) => {
    const filteredUsers = users.filter(typeCondition);
    if (filteredUsers.length === 0) return null;

    return (
      <>
        <h3>{title}</h3>
        <div className="voting-panel">
          {filteredUsers.map((userItem) => {
            const { user_id: userId, email } = userItem;
            return (
              <UserCard key={userId} competitionId={competitionId} userId={userId} userEmail={email} />
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className="list-voting-panel-container">
      {renderUserCategory('Mentors', (userItem) => userItem.type === 'MENTOR')}
      {renderUserCategory('Judges', (userItem) => userItem.type === 'OWNER' || userItem.type === 'JUDGE')}
      {renderUserCategory('Jurors', (userItem) => userItem.type === 'JUROR')}
    </div>
  );
}

export default ListVotingPanel;
