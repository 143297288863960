import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Button from '../../components/generic/button';
import { BURNETT_API_ENDPOINT } from '../../config';
import './header.scss';

function Header({ isAuthenticated, logout, loginWithRedirect, user, getIdTokenClaims, isLoadingComplete }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState(null);
  const [competitionLogo, setCompetitionLogo] = useState(null);
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [showPrimaryLogoClass, setShowPrimaryLogoClass] = useState(false);

  const getLinkClass = (path) => {
    return location.pathname === path ? 'active-link' : '';
  };

  const handleProfileClick = () => {
    navigate(`/edit-profile`);
  };

  const handleCompeteClick = () => {
    navigate(`/`);
  };

  const handlePracticeClick = () => {
    window.location.href = "https://practice.longjump.ai";
  }

  useEffect(() => {
    if (!loadingComplete) return;
    isLoadingComplete();
  }, [loadingComplete]);

  const getUserProfileSettings = async () => {
    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;
      const response = await axios.get(`${BURNETT_API_ENDPOINT}/get-profile`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      const data = response.data;
      setUserProfile(data);

    } catch (err) {}
  }

  const getCompetitionLogo = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      let competitionId = urlParams.get('competition_id');
      
      if (!competitionId) {
        const pathParts = window.location.pathname.split('/');
        const competitionIndex = pathParts.indexOf('competition');
        const editIndex = pathParts.indexOf('edit');
        const inviteIndex = pathParts.indexOf('invite');

        
        if (competitionIndex !== -1 && pathParts[competitionIndex + 1]) {
          competitionId = pathParts[competitionIndex + 1];
        } else if (editIndex !== -1 && pathParts[editIndex + 1]) {
          competitionId = pathParts[editIndex + 1];
        } else if (inviteIndex !== -1 && pathParts[inviteIndex + 1]) {
          competitionId = pathParts[inviteIndex + 1];
        }
      }
  
      if (!competitionId) {
        setCompetitionLogo(null);
        return;
      }
  
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;
      const response = await axios.get(`${BURNETT_API_ENDPOINT}/get-competition-logo?competition_id=${competitionId}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });
  
      const data = response.data;

      if (!data) return;
      const { logo_url: logoUrl } = data;
      if (!logoUrl) return;
  
      const img = new Image();
      img.onload = () => {
        setCompetitionLogo(logoUrl);
      };
      img.onerror = () => {
        setCompetitionLogo(false);
      };
      img.src = logoUrl;
  
    } catch (err) {
      setCompetitionLogo(false);
      console.error(err);
    }
  };

  useEffect(() => {
    if (!competitionLogo) {
      setShowPrimaryLogoClass(true);
      const timer = setTimeout(() => {
        setShowPrimaryLogoClass(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [competitionLogo]);

  useEffect(() => {
    if (!loadingComplete) return;
    getCompetitionLogo();
  }, [loadingComplete, location]);

  useEffect(() => {
    if (!user) return;
    const { email } = user;
    getUserProfileSettings(email);
  }, [user]);

  useEffect(() => {
    if (!user || !userProfile) return;
    const { custom_first_name = "", tenant_admin = false } = userProfile;
    localStorage.setItem('custom_first_name', custom_first_name);
    localStorage.setItem('tenant_admin', tenant_admin);
    setLoadingComplete(true);
  }, [userProfile])

  useEffect(() => {
    if (!isAuthenticated && window.location.href !== "http://localhost:3000/") {
      window.location.href = "https://id.longjump.ai";
    }
  }, [isAuthenticated]);

  const getName = (profileData) => {
    const {
      given_name,
      family_name,
      custom_picture,
      custom_first_name,
      custom_last_name,
      email
    } = profileData;
  
    const firstName = custom_first_name;
    const lastName = custom_last_name;

    user.custom_picture = custom_picture;
  
    if (firstName || lastName) {
      return `${firstName} ${lastName}`.trim();
    } else {
      return email;
    }
  }

  useEffect(() => {
    setIsMobileMenuActive(false);
  }, [location.pathname]);

  const onClickToggleMobileMenu = () => {
    setIsMobileMenuActive(!isMobileMenuActive);
  }

  if (isAuthenticated) {
    return (
      <>
        {isMobileMenuActive ? (
          <div className='header-navigation-mobile-menu'>
            <div className='header-navigation-mobile-toggle' onClick={() => onClickToggleMobileMenu()} />

            <div className='header-navigation-mobile-menu-options'>
              <Link to="/" className={getLinkClass('/')}>Home</Link>
              <Link to="/compete" className={getLinkClass('/compete')}>Compete</Link>
              <Link to="/compare" className={getLinkClass('/compare')}>Compare</Link>
            </div>
          </div>
        ) : <></>}
        <div className="header-container" style={ window.location.pathname === '/edit-profile-form' ? {display: 'none'} : {}}>
          <div className="header-container-inner">
            {competitionLogo ? (
              <div className="org-icon-container" style={{backgroundImage: `url(${competitionLogo})`}} />
            ) : (
              <div className={`org-icon-container ${showPrimaryLogoClass ? 'longjump-primary-logo' : ''}`} />
            )}
            <div className="header-navigation-container">
              <Link to="/" className={getLinkClass('/')}>Home</Link>
              <Link to="/compete" className={getLinkClass('/compete')}>Compete</Link>
              <Link to="/compare" className={getLinkClass('/compare')}>Compare</Link>
            </div>

            <div className='header-navigation-mobile-toggle' onClick={() => onClickToggleMobileMenu()} />

            <div className="user-container">
              <div className='profile-picture-container'>
                <div className='hover-arrow' />
                <div className='profile-picture' style={{backgroundImage: `url(${user.custom_picture})`}}>
                  <div className="hover-card">
                    <h3>ACCOUNT SETTINGS</h3>
                    <b>{userProfile ? getName(userProfile) : ""}</b>
                    <ul>
                      {/* <li>Billing</li> */}
                      <li onClick={handleProfileClick}>Profile</li>
                      <li onClick={() => logout({ logoutParams: { returnTo: window.location.href === "http://localhost:3000/" ? "http://localhost:3000" : "https://id.longjump.ai" }})}>
                          Sign Out
                      </li>
                    </ul>
                    <div className="app-nav">
                      <div className="app-nav-item compete-button" onClick={handleCompeteClick}>
                        <div className="app-nav-icon compete-icon" />
                        Compete
                      </div>
                      <div className="app-nav-item practice-button" onClick={handlePracticeClick}>
                        <div className="app-nav-icon practice-icon" />
                        Practice
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
       window.location.href === "http://localhost:3000/" ? (
        <div className="header-container">
          <div className="header-container-inner">
            <div className="org-icon-container" />
            <Button text="Sign Up" onClick={loginWithRedirect} className="secondary signup-button" />
            <Button text="Log in" onClick={loginWithRedirect} className="primary login-button" />
          </div>
        </div>
        ) : <></>
    );
  }
}

export default Header;
