import React from 'react';
import './popup.scss';

const Popup = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <button className="popup-close" onClick={onClose}>×</button>
        <div className="popup-content">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Popup;
