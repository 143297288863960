import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './create-prize-list.scss';

const Prizes = ({ 
  initialPrizes = [
    { key: 'GRAND PRIZE', value: '$5,000.00 cash' },
    { key: 'DEANS PICK', value: '$1,000.00 cash' },
    { key: '5 RUNNER UPS', value: '$500.00 cash' }
  ], 
  handlePrizesChange 
}) => {
  const [prizes, setPrizes] = useState(initialPrizes);

  useEffect(() => {
    handlePrizesChange(prizes);
  }, [prizes, handlePrizesChange]);

  const addPrize = () => {
    setPrizes([...prizes, { key: '', value: '' }]);
  };

  const handleInputChange = (index, event) => {
    const newPrizes = prizes.map((prize, i) => {
      if (i === index) {
        return { ...prize, [event.target.name]: event.target.value };
      }
      return prize;
    });
    setPrizes(newPrizes);
  };

  const deletePrize = (index) => {
    setPrizes(prizes.filter((_, i) => i !== index));
  };

  return (
    <div className="prizes-container">
      {prizes.map((prize, index) => (
        <div key={index} className="prize">
          <input
            type="text"
            name="key"
            value={prize.key}
            placeholder="Prize Name"
            onChange={(e) => handleInputChange(index, e)}
          />
          <input
            type="text"
            name="value"
            value={prize.value}
            placeholder="Prize Value"
            onChange={(e) => handleInputChange(index, e)}
          />
          <div className="delete-prize-button" onClick={() => deletePrize(index)}>Delete</div>
        </div>
      ))}
      <div className="add-prize-button" onClick={addPrize}>
        + add prize
      </div>
    </div>
  );
};

Prizes.propTypes = {
  initialPrizes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string
    })
  ),
  handlePrizesChange: PropTypes.func.isRequired
};

export default Prizes;
