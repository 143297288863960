import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BURNETT_API_ENDPOINT } from '../../../config';
import './user-card.scss';

const UserCard = ({ competitionId, userId, userEmail = "", isRow }) => {
  const navigate = useNavigate();
  const { getIdTokenClaims } = useAuth0();
  const [userName, setUsername] = useState(userEmail);
  const [profilePicture, setProfilePicture] = useState(null);

  const didMount = async () => {
    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;

      if (userId === "no-profile") return;

      const getUserProfileData = await axios.get(`${BURNETT_API_ENDPOINT}/get-user?competition_id=${competitionId}&user_id=${userId}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      if (!getUserProfileData) return;
      const { data } = getUserProfileData;
      if (!data) return;
      const { user } = data;
      if (!user) return;

      if (user !== "NO_ACCOUNT") {
        const {
          given_name = "",
          family_name = "",
          picture,
          custom_picture,
          custom_first_name,
          custom_last_name,
          email
        } = user;

        const firstName = custom_first_name;
        const lastName = custom_last_name;

        setProfilePicture(custom_picture);

        if (firstName || lastName) {
          setUsername(`${firstName} ${lastName}`.trim());
        } else {
          setUsername(email);
        }
      }

    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      didMount();
    }, Math.random() * 2500);

    return () => clearTimeout(timeout);
  }, []);

  const onClickUserProfile = () => {
    navigate(`/profile/${userId}`);
  }

  return (
    <div className={ isRow ? 'user-display-row' : 'user-card'} onClick={() => onClickUserProfile()}>
      <div className='user-card-image' style={ profilePicture ? {backgroundImage: `url(${profilePicture})`} : {}} />
      <div className='user-card-name'>{userName.includes('@') ? userName.split('@')[0] : userName}</div>
    </div>
  )
};

export default UserCard;
