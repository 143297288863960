import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BURNETT_API_ENDPOINT } from '../../../config';
import './user-card-complex.scss';

const UserCardComplex = ({ competitionId, userId, userEmail = "" }) => {
  const navigate = useNavigate();
  const { getIdTokenClaims } = useAuth0();
  const [userName, setUsername] = useState(userEmail && userEmail.includes('@') ? userEmail.split('@')[0] : "");
  const [userBio, setUserBio] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [profilePicture, setProfilePicture] = useState(null);
  const [userCompSubs, setUserCompSubs] = useState([]);

  const fetchUserData = async (retry = false) => {
    setIsLoading(true);
    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;

      if (userId === "no-profile") return;

      const getUserProfileData = await axios.get(`${BURNETT_API_ENDPOINT}/get-user?competition_id=${competitionId}&user_id=${userId}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      setIsLoading(false);

      if (!getUserProfileData) return;
      const { data } = getUserProfileData;
      if (!data) return;
      const { user } = data;
      if (!user) return;

      if (user !== "NO_ACCOUNT") {
        const {
          given_name = "",
          family_name = "",
          picture,
          custom_picture,
          custom_first_name,
          custom_last_name,
          custom_tags = [],
          custom_bio = "",
          user_comp_subs,
          email
        } = user;

        const firstName = custom_first_name;
        const lastName = custom_last_name;

        setProfilePicture(custom_picture);
        setUserBio(custom_bio);
        setUserCompSubs(user_comp_subs);

        if (firstName || lastName) {
          setUsername(`${firstName} ${lastName}`.trim());
        } else {
          setUsername(email);
        }
      }

    } catch (error) {
      if (error.code && error.code === "ERR_NETWORK" && !retry) {
        setTimeout(() => fetchUserData(true), 1000);
      } else {
        console.error(error);
      }
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchUserData();
    }, Math.random() * 1500);

    return () => clearTimeout(timeout);
  }, []);

  const onClickUserProfile = () => {
    navigate(`/profile/${userId}`);
  }

  const onClickSubmission = (submissionId) => {
    navigate(`/competition/${competitionId}/submission/${submissionId}`);
  }

  return (
    <div className='user-card-complex' style={isLoading ? {opacity: 0} : {}}>
      <div className='user-card-image-container' onClick={() => onClickUserProfile()}>
        <div className='user-card-image' style={ profilePicture ? {backgroundImage: `url(${profilePicture})`} : {}} />
        <div className='user-card-image-default' />
      </div>
      <div className='user-card-name' onClick={() => onClickUserProfile()}>{userName}</div>
      <div className='user-bio'>
        {userBio && userBio.length > 0 ?  userBio.substring(0, 150) + ' ...'  : ''}
      </div>
      <div className="user-submission-items">
        {userCompSubs.map((submission, index) => (
          <div key={index} className="user-submission" onClick={() => onClickSubmission(submission.submission_id)}>
            <div className="submission-name">{submission.submission_name}</div>
            <div className="total-boost-points">
              <div className="boost-icon" />
              <div className="boost-points">{submission.total_points} pts</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
};

export default UserCardComplex;
