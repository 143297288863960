import React, { useState } from 'react';
import Button from '../button';
import { toast } from "react-toastify";

import './manage-key-value-pair-list.scss';

function ManageKeyValuePairList({ activePairList = [], onClose }) {
  const [pairs, setPairs] = useState(activePairList.length > 0 ? activePairList : [{ key: "", value: "" }]);

  const handleAddPair = () => {
    setPairs([...pairs, { key: "", value: "" }]);
  };

  const handleDeletePair = (index) => {
    const newPairs = pairs.filter((_, i) => i !== index);
    setPairs(newPairs);
  };

  const handleKeyInputChange = (index, event) => {
    const newPairs = [...pairs];
    newPairs[index].key = event.target.value;
    setPairs(newPairs);
  };

  const handleValueInputChange = (index, event) => {
    const newPairs = [...pairs];
    newPairs[index].value = event.target.value;
    setPairs(newPairs);
  };

  const handleSavePairs = () => {
    onClose(pairs);
  };

  return (
    <div className="manage-key-value-pair-list-container">
      <div className="pair-list">
        {pairs.map((pair, index) => (
          <div key={index} className="pair-row">
            <input
              type="text"
              placeholder="Link Name"
              value={pair.key}
              onChange={(e) => handleKeyInputChange(index, e)}
              autoComplete="off"
              spellCheck="false"
            />
            <input
              type="text"
              placeholder="Link URL"
              value={pair.value}
              onChange={(e) => handleValueInputChange(index, e)}
              autoComplete="off"
              spellCheck="false"
            />
            <Button text="Delete" onClick={() => handleDeletePair(index)} className="secondary" />
          </div>
        ))}
        <div className="footer-buttons">
          <Button text="Add Link" onClick={handleAddPair} className="secondary add-pair-button" />
          <Button text="Done" onClick={handleSavePairs} className="primary save-button" />
        </div>
      </div>
    </div>
  );
}

export default ManageKeyValuePairList;
