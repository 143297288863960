import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Axios from 'axios';
import moment from 'moment';
import Button from '../../../components/generic/button';
import { BURNETT_API_ENDPOINT } from '../../../config';

import './list-competitions.scss';

const CompetitionList = () => {
  const navigate = useNavigate();
  const { getIdTokenClaims, user } = useAuth0();
  const [competitionData, setCompetitionData] = useState([]);

  const formatDate = (dateString) => {
    return moment(dateString).format('MMMM Do, YYYY');
  };  

  const didMount = async () => {
    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;
  
      const getCompetitionData = await Axios.get(`${BURNETT_API_ENDPOINT}/get-competitions`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });
    
      const { data: resData } = getCompetitionData;
      const { data } = resData;
  
      setCompetitionData(data || []);
  
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    didMount();
  }, []);

  const handleViewClick = (competitionId) => {
    navigate(`/competition/${competitionId}`);
  };

  const handleEditClick = (competitionId) => {
    navigate(`/edit/${competitionId}`);
  };

  const handleInviteClick = (competitionId) => {
    navigate(`/invite/${competitionId}`);
  };

  const handleManageSubmissionClick = (competitionId) => {
    navigate(`/competition/${competitionId}/manage-submission`);
  };

  const findUserTypeByEmail = (users, email) => {
    for (let i = 0; i < users.length; i++) {
      if (users[i].email === email) {
        return users[i].type;
      }
    }
    return null;
  }

  return (
    <ul className="list-competitions">
    {competitionData && competitionData.length > 0 && competitionData.map(competition => {

      const { 
        competition_id,
        competition_name,
        creator,
        competition_start_date,
        competition_deadline_date,
        competition_end_date,
        status,
        users
      } = competition;

      const { email } = user;

      const permissionScope = findUserTypeByEmail(users, email);
      
      return (
        <li key={competition_id} className="competition-item">
          <div className="permission-scope">{permissionScope}</div>
          <h3>{competition_name}</h3>
          <span>{formatDate(competition_start_date)} — {formatDate(competition_end_date)}</span>
          <span>Submission Deadline: {formatDate(competition_deadline_date ? competition_deadline_date : competition_end_date)}</span>
          {/* <span>{status}</span> */}
          <div className="button-container">
            <Button text="View" onClick={()=> handleViewClick(competition_id)} className="primary" />
            {permissionScope === "OWNER" ||
            permissionScope === "ADMIN" ? (
              <>
                <Button text="Edit" onClick={()=> handleEditClick(competition_id)} className="secondary" />
                <Button text="Invite" onClick={()=> handleInviteClick(competition_id)} className="secondary" />
              </>
            ) : <></>}

            {permissionScope === "COMPETITOR" ? (
              <>
                <Button text="My Submissions" onClick={()=> handleManageSubmissionClick(competition_id)} className="secondary" />
              </>
            ) : <></>}
          </div>
        </li>
      )})}
    </ul>
  );
};

export default CompetitionList;
