import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import axios from 'axios';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import CreatePrizeList from '../../components/generic/createPrizeList';
import Popup from '../../components/generic/popup';
import Button from '../../components/generic/button';
import BackButton from '../../components/generic/backButton';
import LoadingDots from '../../components/generic/loadingDots';
import { BURNETT_API_ENDPOINT } from '../../config';
import './edit-competition.scss';
import 'react-datepicker/dist/react-datepicker.css';

const EditCompetition = () => {
  const navigate = useNavigate();
  const { competitionId } = useParams();
  const { user, getIdTokenClaims } = useAuth0();
  const [competitionRules, setCompetitionRules] = useState("");
  const [videoName, setVideoName] = useState("");
  const [logoName, setLogoName] = useState("");
  const [logoType, setLogoType] = useState("");
  const [creator, setCreator] = useState("");
  const [status, setStatus] = useState("");
  const [videoType, setVideoType] = useState("");
  const [competitionPrizeList, setCompetitionPrizeList] = useState([]);
  const [competitionName, setCompetitionName] = useState("");
  const [competitionDescription, setCompetitionDescription] = useState("");
  const [type, setType] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress]= useState(null);
  const [uploadLogoProgress, setUploadLogoProgress]= useState(null);
  const fileInputRef = useRef(null);
  const logoFileInputRef = useRef(null);
  const [videoFile, setVideoFile] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [isLoading, setIsLoading]= useState(false);
  const [isLoadingDelete, setIsLoadingDelete]= useState(false);
  const [userName, setUserName]= useState("");
  const [createCompetitionPopupActive, setCreateCompetitionPopupActive]= useState(false);
  const [competitionPrizes, setCompetitionPrizes]= useState([]);
  const [startDate, setStartDate] = useState(null);
  const [deadlineDate, setDeadlineDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [userPermissisonScope, setUserPermissisonScope] = useState(null);
  const [statusToggle, setStatusToggle] = useState(false);
  const [toggleDeleteConfrimPopup, setToggleDeleteConfrimPopup] = useState(false);

  const findUserTypeByEmail = (users, email) => {
    for (let i = 0; i < users.length; i++) {
      if (users[i].email === email) {
        return users[i].type;
      }
    }
    return null;
  }

  useEffect(() => {
    const fetchCompetitionData = async () => {
      try {
        const claims = await getIdTokenClaims();
        const idToken = claims.__raw;
        const response = await axios.get(`${BURNETT_API_ENDPOINT}/get-competition?competition_id=${competitionId}`, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json"
          }
        });

        const data = response.data;
        setCompetitionRules(data.competition_rules);
        setVideoName(data.video_name);
        setCreator(data.creator);
        setStatus(data.status);
        setVideoType(data.video_type);
        setCompetitionPrizeList(data.competition_prize_list);
        setCompetitionName(data.competition_name);
        setCompetitionDescription(data.competition_description);
        setType(data.type);
        setVideoUrl(data.video_url);
        setUsers(data.users);

        setStartDate(data.competition_start_date);
        setDeadlineDate(data.competition_deadline_date)
        setEndDate(data.competition_end_date);

        if (data.status === "PUBLISHED") setStatusToggle(true);

        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchCompetitionData();
  }, [competitionId, getIdTokenClaims]);

  useEffect(() => {
    if (!user || !users || competitionName.length === 0) return;
    const { email } = user;
    if (!email) return;
    const permissionScope = findUserTypeByEmail(users, email);
    setUserPermissisonScope(permissionScope);

  }, [user, users, competitionName]);

  const handleSaveCompetitionSubmit = async (e) => {
    e.preventDefault();
    if (!competitionName || competitionName.length <= 3) {
      toast.warning("Competition names need to be greater than three characters.");
      return;
    }

    if (!startDate || !endDate) {
      toast.warning("Please select competition start and end dates.");
      return;
    }
    
    if (isLoading) return;
    setIsLoading(true);

    const params = {
      competition_id: competitionId,
      competition_name: competitionName,
      competition_description: competitionDescription,
      competition_rules: competitionRules,
      competition_start_date: startDate,
      competition_deadline_date: deadlineDate,
      competition_end_date: endDate,
      competition_prize_list: competitionPrizes,
      status: status
    }

    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;

      const updateResult = await axios.post(`${BURNETT_API_ENDPOINT}/update-competition`, params, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      setIsLoading(false);
      toast.success('Competition saved successfully.');
      navigate('/');

    } catch (error) {
      console.error(error);
      toast.error('Failed to save competition.');
      setIsLoading(false);
    }
  }

  const handleCompetitionNameInputChange = (e) => {
    setCompetitionName(e.target.value);
  };

  const handleCompetitionDescriptionInputChange = (e) => {
    setCompetitionDescription(e.target.value);
  };

  const handleCompetitionRulesInputChange = (e) => {
    setCompetitionRules(e.target.value);
  }

  const handlePrizesChange = (prizes) => {
    setCompetitionPrizes(prizes)
  }

  useEffect(() => {
    const tempStatus = statusToggle ? 'PUBLISHED' : 'DRAFT';
    setStatus(tempStatus);
  }, [statusToggle]);

  const toggleStatus = () => {
    setStatusToggle(!statusToggle);
  };

  const handleToggleDeleteClick = () => {
    setToggleDeleteConfrimPopup(!toggleDeleteConfrimPopup);
  }

  const handleConfirmDeleteClick = async () => {
    if (isLoadingDelete) return;
    setIsLoadingDelete(true);
  
    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;

      const deleteParams = {
        competition_id: competitionId
      }

      const updateResult = await axios.post(`${BURNETT_API_ENDPOINT}/delete-competition`, deleteParams, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      setIsLoadingDelete(false);
      toast.success('Competition deleted successfully.');
      navigate('/');
      
    } catch (error) {
      console.error(error);
      toast.error('Failed to delete competition.');
      setIsLoadingDelete(false);
    }
  }

  useEffect(() => {
    if (!videoFile) return;

    const { name, type, size } = videoFile;

    setVideoName(name);
    setVideoType(type);

  }, [videoFile]);

  useEffect(() => {
    if (!logoFile) return;

    const { name, type, size } = logoFile;

    setLogoName(name);
    setLogoType(type);

  }, [logoFile]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setVideoFile(file);
    }
  };

  const handleLogoFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLogoFile(file);
    }
  };

  const handleReplaceVideoClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleReplaceLogoClick = () => {
    if (logoFileInputRef.current) {
      logoFileInputRef.current.click();
    }
  };

  const handleConfirmUploadClick = async () => {
    const newVideoParams = {
      competition_id: competitionId,
      video_name: videoFile.name,
      video_type: videoFile.type,
      video_owner: user.email
    }

    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;

      const replaceVideoResult = await axios.post(`${BURNETT_API_ENDPOINT}/replace-competition-video`, newVideoParams, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      const { data } = replaceVideoResult;

      const { presigned_url: preSignedUrl } = data;

      await axios.put(preSignedUrl, videoFile, {
        headers: {
          "Content-Type": videoFile.type
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentCompleted = Math.round((loaded * 100) / total);
          setUploadProgress(percentCompleted);
        }
      });

      setVideoFile(null);
      setUploadProgress(null);
      toast.success('Competition video replaced successfully.');

      window.location.reload();
      
    } catch (error) {
      console.error(error);
      toast.error('Failed to replace competition video.');
      setIsLoading(false);
    }
  }

  const handleConfirmUploadLogoClick = async () => {
    const newLogoParams = {
      competition_id: competitionId,
      file_type: logoFile.type,
    }

    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;

      const replaceLogoResult = await axios.post(`${BURNETT_API_ENDPOINT}/update-competition-logo`, newLogoParams, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      const { data } = replaceLogoResult;

      const { presigned_url: preSignedUrl } = data;

      await axios.put(preSignedUrl, logoFile, {
        headers: {
          "Content-Type": logoFile.type
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentCompleted = Math.round((loaded * 100) / total);
          setUploadLogoProgress(percentCompleted);
        }
      });

      setLogoFile(null);
      setUploadLogoProgress(null);
      toast.success('Competition logo replaced successfully.');

      window.location.reload();
      
    } catch (error) {
      console.error(error);
      toast.error('Failed to replace competition logo.');
      setIsLoading(false);
    }
  }

  const handleCancelVideoReplace = () => {
    if (uploadProgress) return;
    setVideoFile(null);
    setVideoType(null);
    setVideoName(null);
  }

  const handleCancelLogoReplace = () => {
    if (uploadLogoProgress) return;
    setLogoFile(null);
    setLogoType(null);
    setLogoName(null);
  }

  if (loading) return <LoadingDots />;
  if (error) return <p>Error: {error}</p>;

  return (
    <>
      {toggleDeleteConfrimPopup ? (
        <Popup isOpen={toggleDeleteConfrimPopup} onClose={handleToggleDeleteClick}>
          <div className="confirm-delete-popup">
            <h3>Are you sure you want to delete this competition?</h3>
            <b>{competitionName}</b>
            <span>This action is irreversible. All competition data (including participant submissions) will be permanently deleted.</span>
            <Button text={!isLoadingDelete ? 'Confirm Delete Competition' : 'Loading'} onClick={()=> handleConfirmDeleteClick()} className="danger" />
        </div>
        </Popup>
      ) : <></>}

      {videoFile ? (
        <div style={uploadProgress ? {pointerEvents: "none"} : {}}>
          <Popup isOpen={videoFile} onClose={handleCancelVideoReplace}>
            <div className="confirm-replace-video-popup">
            {uploadProgress ? (
              <div className="upload-progress">
                <span>Uploading {videoFile.name}:</span>
                <b>{uploadProgress}%</b>
              </div>
            ) : (
              <>
                <h3>Are you sure you want to replace the existing competition video with this video?</h3>
                <b>{videoName}</b>
                <Button text={!isLoadingDelete ? 'Confirm & Upload' : 'Loading'} onClick={()=> handleConfirmUploadClick()} className="primary" />
              </>
            )}
            </div>
          </Popup>
        </div>
      ) : <></>}

      {logoFile ? (
        <div style={uploadLogoProgress ? {pointerEvents: "none"} : {}}>
          <Popup isOpen={logoFile} onClose={handleCancelLogoReplace}>
            <div className="confirm-replace-video-popup">
            {uploadLogoProgress ? (
              <div className="upload-progress">
                <span>Uploading {logoFile.name}:</span>
                <b>{uploadLogoProgress}%</b>
              </div>
            ) : (
              <>
                <h3>Are you sure you want to replace the existing competition logo with this image?</h3>
                <b>{logoName}</b>
                <Button text={!isLoadingDelete ? 'Confirm & Upload' : 'Loading'} onClick={()=> handleConfirmUploadLogoClick()} className="primary" />
              </>
            )}
            </div>
          </Popup>
        </div>
      ) : <></>}

      <div className="edit-competition-container">
        <div className="edit-competition-container-inner">
          <BackButton />
          <h2>Edit competition</h2>

          <div className="edit-comp-logo-container">
            <div className="comp-logo" />
            <input
              type="file"
              className="file-input"
              accept="image/jpeg, image/png"
              onChange={handleLogoFileChange}
              ref={logoFileInputRef}
              style={{ display: 'none' }}
            />
            <Button text='Replace Logo' onClick={()=> handleReplaceLogoClick()} className="primary replace-video-button" />
          </div>
          <div style={{maxWidth: 350, color: "#878c91"}}>
            Upload a competition logo with optimal dimensions of 180x60 pixels for best display.
          </div>

          <video width="100%" height="100%" controls>
            <source src={videoUrl} type={videoType} />
            Your browser does not support the video tag.
          </video>

          <Button text='Replace Video' onClick={()=> handleReplaceVideoClick()} className="primary replace-video-button" />

          <input
            type="file"
            className="file-input"
            accept="video/mp4"
            onChange={handleFileChange}
            ref={fileInputRef}
            style={{ display: 'none' }}
          />

          <form onSubmit={handleSaveCompetitionSubmit}>
            <label htmlFor="competition-name">Competition Name:</label>
            <input
              type="text"
              id="competition-name"
              value={competitionName}
              onChange={handleCompetitionNameInputChange}
              required
            />
            <label htmlFor="competition-description">Description:</label>
            <textarea
              id="competition-description"
              value={competitionDescription}
              onChange={handleCompetitionDescriptionInputChange}
              required
            />
            <label htmlFor="competition-rules">Rules:</label>
            <textarea
              id="competition-rules"
              value={competitionRules}
              onChange={handleCompetitionRulesInputChange}
              required
            />
            <div className="date-range-picker">
              <div className="date-picker-section">
                <label>Start Date</label>
                <DatePicker
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Start Date"
                />
              </div>
              <div className="date-picker-section">
                <label>Deadline</label>
                <DatePicker
                  selected={deadlineDate}
                  onChange={date => setDeadlineDate(date)}
                  startDate={deadlineDate}
                  endDate={deadlineDate}
                  placeholderText="Deadline"
                />
              </div>
              <div className="date-picker-section">
                <label>End Date</label>
                <DatePicker
                  selected={endDate}
                  onChange={date => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  placeholderText="End Date"
                />
              </div>
            </div>
            <label htmlFor="competition-rules">Prizes:</label>
            <CreatePrizeList initialPrizes={competitionPrizeList} handlePrizesChange={(prizes) => handlePrizesChange(prizes)}/>
            <br />
            <button type="submit">{isLoading ? "Loading" : "Save Changes"}</button>
            <div className="toggle-status-button">
              <input 
                type="checkbox" 
                checked={statusToggle} 
                onChange={toggleStatus} 
                id="statusCheckbox"
              />
              <label htmlFor="statusCheckbox" className="switch">
                <span className="slider"></span>
                <span className="status-text">{status}</span>
              </label>
            </div>
          </form>

          

          {userPermissisonScope && userPermissisonScope === "OWNER" ? (
            <div className="admin-danger-zone">
              <h3>Danger Zone</h3>
              <Button text="Delete Competition" onClick={()=> handleToggleDeleteClick()} className="danger" />
            </div>
          ) : <></>}
        </div>
      </div>
    </>
  );
};

export default EditCompetition;
