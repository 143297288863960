import React, { useState } from 'react';
import Button from '../button';
import { toast } from "react-toastify";

import './manage-team-list.scss';

function ManageTeamList({ activeTeamList = [], onClose, activeUserEmail = "" }) {
  const [users, setUsers] = useState(activeTeamList.length > 0 ? activeTeamList : ['']);
  const [userSessionEmail, setUserSessionEmail] = useState(activeUserEmail);

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleAddUser = () => {
    setUsers([...users, '']);
  };

  const handleDeleteUser = (index) => {
    const newUsers = users.filter((_, i) => i !== index);
    setUsers(newUsers);
  };

  const handleInputChange = (index, event) => {
    const newUsers = [...users];
    newUsers[index] = event.target.value;
    setUsers(newUsers);
  };

  const handleSaveUsers = () => {
    const invalidEmails = users.filter(email => !validateEmail(email));
    if (invalidEmails.length > 0) {
      toast.error(`Invalid emails: ${invalidEmails.join(', ')}`);
      return;
    }
    onClose(users);
  };

  return (
    <div className="manage-team-list-container">
      <div className="user-list">
        {users.map((user, index) => (
          <div key={index} className="user-row">
            <input
              type="email"
              placeholder="Email"
              value={user}
              onChange={(e) => handleInputChange(index, e)}
              autoComplete="off"
              spellCheck="false"
            />
            <Button text="Delete" onClick={() => handleDeleteUser(index)} className="Secondary" />
          </div>
        ))}
        <div className="footer-buttons">
          <Button text="Add Teammate" onClick={handleAddUser} className="secondary add-user-button" />
          <Button text="Done" onClick={handleSaveUsers} className="primary save-button" />
        </div>
      </div>
    </div>
  );
}

export default ManageTeamList;
