import React from 'react';
import PropTypes from 'prop-types';
import './button.scss';

const Button = ({ text, onClick, type = 'button', disabled = false, className = '' }) => (
  <button 
    type={type} 
    className={`button ${className}`} 
    onClick={onClick} 
    disabled={disabled}
  >
    {text}
  </button>
);

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  disabled: PropTypes.bool,
  className: PropTypes.string
};

export default Button;
