import React, { useState, useEffect } from 'react';
import './multi-select.scss';

const MultiSelect = ({ options = [], activeSelections = [], onSelectionChange }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    setSelectedOptions(activeSelections);
  }, [activeSelections]);

  const handleOptionClick = (option) => {
    if (!onSelectionChange) return;
    let newSelectedOptions;

    if (selectedOptions.includes(option)) {
      newSelectedOptions = selectedOptions.filter((selectedOption) => selectedOption !== option);
    } else {
      newSelectedOptions = [...selectedOptions, option];
    }

    setSelectedOptions(newSelectedOptions);
    if (onSelectionChange) {
      onSelectionChange(newSelectedOptions);
    }
  };

  const renderOptions = onSelectionChange ? options : activeSelections;

  return (
    <div className="multi-select">
      {renderOptions.map((option, index) => (
        <div
          key={index}
          className={`option ${selectedOptions.includes(option) ? 'selected' : ''}`}
          style={!onSelectionChange ? {pointerEvents: "none"} : {}}
          onClick={() => handleOptionClick(option)}
        >
          {option}
        </div>
      ))}
    </div>
  );
};

export default MultiSelect;
