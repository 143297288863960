import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { toast } from "react-toastify";
import moment from 'moment';
import { BURNETT_API_ENDPOINT } from '../../config';
import BackButton from '../../components/generic/backButton';
import './public-profile.scss';

function PublicProfile() {
  const navigate = useNavigate();
  const { publicProfileId } = useParams();
  const { user, getIdTokenClaims } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [email, setEmail] = useState("");
  const [bio, setBio] = useState("");
  const [location, setLocation] = useState("");
  const [publishedSubmissions, setPublishedSubmissions] = useState([]);

  const getUserProfileSettings = async () => {
    try {
      setIsLoading(true);
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;
      const response = await axios.get(`${BURNETT_API_ENDPOINT}/get-user-public-profile?user_id=${publicProfileId}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      const data = response.data;
      const { user } = data;

      const {
        custom_first_name = "",
        custom_last_name = "",
        custom_bio = "",
        custom_location = "",
        custom_picture = "",
        email,
        published_submissions = []
      } = user;

      setFirstName(custom_first_name);
      setLastName(custom_last_name);
      setProfilePicture(custom_picture)
      setBio(custom_bio);
      setLocation(custom_location);
      setEmail(email);
      setPublishedSubmissions(published_submissions);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!publicProfileId || publicProfileId === "no-profile") return;
    getUserProfileSettings();
  }, [publicProfileId]);

  const handleViewCompetitionClick = (path) => {
    navigate(path);
  };

  return (
    <div className="public-profile-container">
      <BackButton />
      <div className="public-profile-container-content">
        <div className="user-profile-image-container">
          <div className="user-profile-image" style={ profilePicture ? {backgroundImage: `url(${profilePicture})`} : {}} />
          <div className="user-profile-image-default" />
        </div>
        <div className="about-profile">
          <div className="user-submissions">
            {publishedSubmissions.map((submission, index) => {
              return (
                <div
                  key={index}
                  className="submission-header"
                  onClick={() => handleViewCompetitionClick(`/competition/${submission.competition_id}/submission/${submission.submission_id}`)}
                >
                  <h3>
                    {submission.submission_name}
                  </h3>
                  <div className="total-boost-points">
                    <div className="boost-icon" />
                    <div className="boost-points">{submission.total_points} pts</div>
                  </div>
                </div>
              )
            })}
          </div>
        
          <h1>{firstName} {lastName}</h1>
          <b>{location}</b>
          <p>{bio}</p>
        </div>
      </div>
    </div>
  )
}

export default PublicProfile;