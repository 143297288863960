import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { toast } from "react-toastify";
import Popup from '../../components/generic/popup';
import Button from '../../components/generic/button';
import UserCard from '../../components/generic/userCard';
import ManageUserTypeList from '../../components/generic/manageUserTypeList';
import BackButton from '../../components/generic/backButton';
import LoadingDots from '../../components/generic/loadingDots';
import { BURNETT_API_ENDPOINT } from '../../config';
import './invite-competition.scss';

function InviteCompetition() {
  const { competitionId } = useParams();
  const { user, getIdTokenClaims } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [competitionName, setCompetitionName] = useState("");
  const [error, setError] = useState(null);
  const [permissionScope, setPermissionScope] = useState(null);

  const [manageCompetitorsPopupActive, setManageCompetitorsPopupActive] = useState(false);
  const [manageMentorsPopupActive, setManageMentorsPopupActive] = useState(false);
  const [manageJudgesPopupActive, setManageJudgesPopupActive] = useState(false);
  const [manageJurorsPopupActive, setManageJurorsPopupActive] = useState(false);
  const [manageAdminsPopupActive, setManageAdminsPopupActive] = useState(false);

  const [competitorList, setCompetitorList] = useState([]);
  const [judgeList, setJudgeList] = useState([]);
  const [jurorList, setJurorList] = useState([]);
  const [adminList, setAdminList] = useState([]);
  const [mentorList, setMentorList] = useState([]);


  const findUserTypeByEmail = (users, email) => {
    for (let i = 0; i < users.length; i++) {
      if (users[i].email === email) {
        return users[i].type;
      }
    }
    return null;
  }

  const findUsersByType = (users, type) => {
    let matchedUsers = [];
    for (let i = 0; i < users.length; i++) {
      if (users[i].type === type) {
        matchedUsers.push(users[i]);
      }
    }
    return matchedUsers;
  };

  const fetchCompetitionData = async () => {
    try {
      setIsLoading(true);
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;
      const response = await axios.get(`${BURNETT_API_ENDPOINT}/get-competition?competition_id=${competitionId}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      const data = response.data;

      const { users: currentUserData } = data;

      const { email: userSessionEmail } = user;

      const tempPermissionScope = findUserTypeByEmail(currentUserData, userSessionEmail);

      setPermissionScope(tempPermissionScope);

      const tempCompetitorList = findUsersByType(currentUserData, 'COMPETITOR');
      setCompetitorList(tempCompetitorList);

      const tempMentorList = findUsersByType(currentUserData, 'MENTOR');
      setMentorList(tempMentorList);

      const tempJudgeList = findUsersByType(currentUserData, 'JUDGE');
      setJudgeList(tempJudgeList);

      const tempJurorList = findUsersByType(currentUserData, 'JUROR');
      setJurorList(tempJurorList);

      const tempAdminList = findUsersByType(currentUserData, 'ADMIN');
      setAdminList(tempAdminList);

      setCompetitionName(data.competition_name);
      setIsLoading(false);
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    if (!competitionId) return;
    fetchCompetitionData();
  }, [competitionId, getIdTokenClaims]);

  const handleAddCompetitor = () => {
    setManageCompetitorsPopupActive(!manageCompetitorsPopupActive);
    if (manageCompetitorsPopupActive) fetchCompetitionData();
  };

  const handleAddMentor = () => {
    setManageMentorsPopupActive(!manageMentorsPopupActive);
    if (manageMentorsPopupActive) fetchCompetitionData();
  };

  const handleAddJudge = () => {
    setManageJudgesPopupActive(!manageJudgesPopupActive);
    if (manageJudgesPopupActive) fetchCompetitionData();
  };

  const handleAddJuror = () => {
    setManageJurorsPopupActive(!manageJurorsPopupActive);
    if (manageJurorsPopupActive) fetchCompetitionData();
  };

  const handleAddAdmin = () => {
    setManageAdminsPopupActive(!manageAdminsPopupActive);
    if (manageAdminsPopupActive) fetchCompetitionData();
  };


  return (
    <div className="invite-competition-container">

      {manageCompetitorsPopupActive && (
        <Popup isOpen={manageCompetitorsPopupActive} onClose={handleAddCompetitor}>
          <h2>Manage Competitors</h2>
          <p>Invite users to compete in this competition.</p>
          <ManageUserTypeList userType="COMPETITOR" onClose={handleAddCompetitor} />
        </Popup>
      )}

      {manageMentorsPopupActive && (
        <Popup isOpen={manageMentorsPopupActive} onClose={handleAddMentor}>
          <h2>Manage Mentors</h2>
          <p>Invite mentors to help motivate competition competitors</p>
          <ManageUserTypeList userType="MENTOR" onClose={handleAddMentor} />
        </Popup>
      )}

      {manageJudgesPopupActive && (
        <Popup isOpen={manageJudgesPopupActive} onClose={handleAddJudge}>
          <h2>Manage Judges</h2>
          <p>Invite judges to help jurors pick competition winners.</p>
          <ManageUserTypeList userType="JUDGE" onClose={handleAddJudge} />
        </Popup>
      )}

      {manageJurorsPopupActive && (
        <Popup isOpen={manageJurorsPopupActive} onClose={handleAddJuror}>
          <h2>Manage Jurors</h2>
          <p>Invite jurors to vote on competitor submissions.</p>
          <ManageUserTypeList userType="JUROR" onClose={handleAddJuror} />
        </Popup>
      )}

      {manageAdminsPopupActive && (
        <Popup isOpen={manageAdminsPopupActive} onClose={handleAddAdmin}>
          <h2>Manage Admins</h2>
          <p>Invite admins to help manage this competition.</p>
          <ManageUserTypeList userType="ADMIN" onClose={handleAddAdmin} />
        </Popup>
      )}

      <BackButton />
      <h1>{competitionName}</h1>
      {error && <p className="error">{error}</p>}
      {isLoading ? (
        <LoadingDots />)
      : (
        <div className="user-list">
          <div className="section competitors">
            <h2>Competitors</h2>
            <p>Build your competition roster by inviting your students</p>
            {competitorList && competitorList.length > 0 ? (
              <div className="user-container-row">
                {competitorList.map((item) => {
                  const { user_id: userId, email } = item;
                  return (<UserCard key={userId} competitionId={competitionId} userId={userId} userEmail={email} isRow={true} />)
                })}
              </div>
            ) : <></>}
            <Button text="Add Competitor" onClick={()=> handleAddCompetitor()} className="primary" />
          </div>
          <div className="section mentors">
            <h2>Mentors</h2>
            <p>Invite mentors to help motivate the students</p>
              {mentorList && mentorList.length > 0 ? (
                <div className="user-container">
                  {mentorList.map((item) => {
                    const { user_id: userId, email } = item;
                    return (<UserCard key={userId} competitionId={competitionId} userId={userId} userEmail={email} />)
                  })}
                </div>
              ) : <></>}
            <Button text="Add Mentor" onClick={()=> handleAddMentor()} className="primary" />
          </div>
          <div className="section judges">
            <h2>Judges</h2>
            <p>Invite impartial judges who to evaluate and help jurors pick competition winners</p>
            {judgeList && judgeList.length > 0 ? (
              <div className="user-container">
                {judgeList.map((item) => {
                  const { user_id: userId, email } = item;
                  return (<UserCard key={userId} competitionId={competitionId} userId={userId} userEmail={email} />)
                })}
              </div>
            ) : <></>}
            <Button text="Add Judge" onClick={()=> handleAddJudge()} className="primary" />
          </div>
          <div className="section jurors">
            <h2>Jurors</h2>
            <p>Invite students and advisors to be part of your jury pool</p>
            {jurorList && jurorList.length > 0 ? (
              <div className="user-container">
                {jurorList.map((item) => {
                  const { user_id: userId, email } = item;
                  return (<UserCard key={userId} competitionId={competitionId} userId={userId} userEmail={email} />)
                })}
              </div>
            ) : <></>}
            <Button text="Add Juror" onClick={()=> handleAddJuror()} className="primary" />
          </div>
          {permissionScope === "OWNER" || permissionScope === "ADMIN" ? (
            <div className="section admins">
              <h2>Admins</h2>
              <p>Invite admins to help manage this competition</p>
              {adminList && adminList.length > 0 ? (
                <div className="user-container">
                  {adminList.map((item) => {
                    const { user_id: userId, email } = item;
                    return (<UserCard key={userId} competitionId={competitionId} userId={userId} userEmail={email} />)
                  })}
                </div>
              ) : <></>}
                <Button text="Add Admin" onClick={()=> handleAddAdmin()} className="primary" />
            </div>
          ) : <></>}
        </div>)}
    </div>
  );
}

export default InviteCompetition;
