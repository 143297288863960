import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Axios from 'axios';
import moment from 'moment';
import Button from '../../components/generic/button';
import Popup from '../../components/generic/popup';
import UserCardComplex from '../../components/generic/userCardComplex';
import BackButton from '../../components/generic/backButton';
import CompetitionStatus from '../../components/competition/competitionStatus';
import { BURNETT_API_ENDPOINT } from '../../config';
import './compare.scss';
import { toast } from 'react-toastify';

function Compare() {
  const navigate = useNavigate();
  const { getIdTokenClaims, user } = useAuth0();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [competitionData, setCompetitionData] = useState([]);
  const [competitionFilters, setCompetitionFilters] = useState([]);
  const [selectedCompetitionId, setSelectedCompetitionId] = useState('');
  const [selectedCompetitionData, setSelectedCompetitionData] = useState(null);
  const [selectedCompetitors, setSelectedCompetitors] = useState([]);
  const [comparedCompetitors, setComparedCompetitors] = useState([]);
  const [hideNonCompared, setHideNonCompared] = useState(false);
  const [selectedFilterValue, setSelectedFilterValue] = useState('');
  const [savedFilters, setSavedFilters] = useState([]);
  const [toggleSaveFilterConfrimPopup, setToggleSaveFilterConfrimPopup] = useState(false);
  const [isLoadingSaveFilter, setIsLoadingSaveFilter] = useState(false);
  const [newFilterName, setNewFilterName] = useState('');

  const formatDate = (dateString) => {
    return moment(dateString).format('MMMM Do, YYYY');
  };

  const didMount = async () => {
    try {
      setIsLoading(true);
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;

      const getCompetitionData = await Axios.get(`${BURNETT_API_ENDPOINT}/get-competitions`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      const { data: resData } = getCompetitionData;
      const { data } = resData;

      setCompetitionData(data || []);
      const filters = (data || []).map(comp => ({
        competition_name: comp.competition_name,
        competition_id: comp.competition_id,
      }));

      const competitionIdFromQuery = searchParams.get('competition_id');
      const userIdsFromQuery = searchParams.get('user_ids');

      if (competitionIdFromQuery) {
        setSelectedCompetitionId(competitionIdFromQuery);
        const selectedData = data.find(comp => comp.competition_id === competitionIdFromQuery);
        if (selectedData) {
          setSelectedCompetitionData(selectedData);
          const tempUsers = selectedData.users.filter(user => user.type === "COMPETITOR");
          setSelectedCompetitors(tempUsers);

          if (userIdsFromQuery) {
            const userIdsArray = userIdsFromQuery.split(',');
            const comparedUsers = userIdsArray.map(userId => ({ user_id: userId }));
            setComparedCompetitors(comparedUsers);
            
            // Check if userIdsFromQuery matches any saved filter
            const matchingFilter = savedFilters.find(filter => filter.filter_query === userIdsFromQuery);
            if (matchingFilter) {
              setSelectedFilterValue(userIdsFromQuery);
            } else {
              setSelectedFilterValue('');
            }

            getSavedFilters();
            setHideNonCompared(true);
          }
        }
      } else if (filters.length > 0) {
        setSelectedCompetitionId(data[0].competition_id);
        setSelectedCompetitionData(data[0]);
        setTimeout(() => {
          const tempUsers = data[0].users.filter(user => user.type === "COMPETITOR");
          setSelectedCompetitors(tempUsers);
        }, 100); 
      }

      setCompetitionFilters(filters);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  const getSavedFilters = async () => {
    const claims = await getIdTokenClaims();
    const idToken = claims.__raw;

    const competitionIdFromQuery = searchParams.get('competition_id');

    if (!selectedCompetitionId) {
      return;
    }

    const getSavedFiltersResult = await Axios.get(`${BURNETT_API_ENDPOINT}/get-competition-filters?competition_id=${selectedCompetitionId}`, {
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json"
      }
    });

    const { data } = getSavedFiltersResult;
    const { filters } = data;
    setSavedFilters(filters);

    const userIdsFromQuery = searchParams.get('user_ids');
    if (userIdsFromQuery) {
      const matchingFilter = filters.find(filter => filter.filter_query === userIdsFromQuery);
      if (matchingFilter) {
        setSelectedFilterValue(userIdsFromQuery);
      } else {
        setSelectedFilterValue('');
      }
    }
  }

  useEffect(() => {
    didMount();
    getSavedFilters();
  }, []);

  useEffect(() => {
    if (hideNonCompared && comparedCompetitors.length <= 1) {
      setHideNonCompared(false);
      setSelectedFilterValue("");
    }
  }, [comparedCompetitors, hideNonCompared]);

  const onClickCompName = () => {
    navigate(`/competition/${selectedCompetitionId}`);
  }

  useEffect(() => {
    if (!selectedCompetitionId) return;

    setSearchParams(prevParams => {
      const newParams = new URLSearchParams(prevParams);
      newParams.set('competition_id', selectedCompetitionId);
      return newParams;
    });
  }, [selectedCompetitionId, setSearchParams]);

  useEffect(() => {
    if (!selectedCompetitionId || selectedCompetitionId === "") return;
    getSavedFilters();

  }, [selectedCompetitionId]);

  const handleCompetitionChange = (event) => {
    setIsLoading(true);
    const selectedId = event.target.value;
    setSelectedCompetitionId(selectedId);
    setSelectedFilterValue("");
    setSavedFilters([]);

    setSearchParams(prevParams => {
      const newParams = new URLSearchParams(prevParams);
      newParams.set('competition_id', selectedId);
      newParams.delete('user_ids');
      return newParams;
    });

    const selectedData = competitionData.find(comp => comp.competition_id === selectedId);
    setSelectedCompetitionData(selectedData);

    const tempUsers = selectedData.users.filter(user => user.type === "COMPETITOR");
    setSelectedCompetitors(tempUsers);

    setComparedCompetitors([]);
    setHideNonCompared(false);

    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  };

  const handleSavedFilterChange = (event) => {
    const selectedFilterId = event.target.value;
    setSelectedFilterValue(selectedFilterId);

    if (selectedFilterId.length > 0) {
      setSearchParams(prevParams => {
        const newParams = new URLSearchParams(prevParams);
        newParams.set('user_ids', selectedFilterId);
        return newParams;
      });
      const userIdsArray = selectedFilterId.split(',');
      const comparedUsers = userIdsArray.map(userId => ({ user_id: userId }));
      setComparedCompetitors(comparedUsers);
      setHideNonCompared(true);
    }
  }

  const toggleUserCompare = (competitorItem) => {
    setSelectedFilterValue("");
    setComparedCompetitors(prevState => {
      const isCurrentlyCompared = prevState.some(item => item.user_id === competitorItem.user_id);
      const updatedComparedCompetitors = isCurrentlyCompared
        ? prevState.filter(item => item.user_id !== competitorItem.user_id)
        : [...prevState, competitorItem];

      if (hideNonCompared) {
        setSearchParams(prevParams => {
          const newParams = new URLSearchParams(prevParams);
          const userIds = updatedComparedCompetitors.map(item => item.user_id).join(',');
          newParams.set('competition_id', selectedCompetitionId);
          if (userIds) {
            newParams.set('user_ids', userIds);
          } else {
            newParams.delete('user_ids');
          }
          return newParams;
        });
      } else {
        setSearchParams(prevParams => {
          const newParams = new URLSearchParams(prevParams);
          newParams.delete('user_ids');
          return newParams;
        });
      }

      return updatedComparedCompetitors;
    });
  };

  const onClickCompareSelections = () => {
    setSelectedFilterValue("");
    if (hideNonCompared) {
      setComparedCompetitors([]);
      setHideNonCompared(false);
      setSearchParams(prevParams => {
        const newParams = new URLSearchParams(prevParams);
        newParams.delete('user_ids');
        return newParams;
      });
    } else {
      setHideNonCompared(true);
      setSearchParams(prevParams => {
        const newParams = new URLSearchParams(prevParams);
        const userIds = comparedCompetitors.map(item => item.user_id).join(',');
        newParams.set('competition_id', selectedCompetitionId);
        newParams.set('user_ids', userIds);
        return newParams;
      });
    }
  }

  const handleToggleSaveFilterClick = () => {
    if (isLoadingSaveFilter) return;
    setToggleSaveFilterConfrimPopup(!toggleSaveFilterConfrimPopup);
  }

  const handleNewFilterNameInputChange = (e) => {
    if (isLoadingSaveFilter) return;
    setNewFilterName(e.target.value);
  };

  const handleConfirmSaveFilterClick = async () => {
    if (isLoadingSaveFilter) return;

    if (!newFilterName || newFilterName.length < 3 || newFilterName.length > 20) {
      toast.warning("Filter names must be at least 3 characters long, and not greater than 20.")
      return;
    }

    setIsLoadingSaveFilter(true);

    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;

      const userIdsFromQuery = searchParams.get('user_ids');

      const newFilterData = {
        competition_id: selectedCompetitionId,
        filter_name: newFilterName,
        filter_query: userIdsFromQuery
      }

      await Axios.post(`${BURNETT_API_ENDPOINT}/create-competition-filter`, newFilterData, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      setIsLoadingSaveFilter(false);
      setNewFilterName("");
      handleToggleSaveFilterClick();
      getSavedFilters();

    } catch (error) {
      console.error(error);
      toast.error('Failed to save filter.');
      setIsLoadingSaveFilter(false);
    }
  }

  const onClickDeleteFilter = async () => {
    const claims = await getIdTokenClaims();
    const idToken = claims.__raw;

    const newFilterData = {
      competition_id: selectedCompetitionId,
      filter_name: selectedFilterValue
    }

    await Axios.post(`${BURNETT_API_ENDPOINT}/delete-competition-filter`, newFilterData, {
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json"
      }
    });

    onClickCompareSelections();
    getSavedFilters();
  }

  return (
    <div className="compare-container">
      {toggleSaveFilterConfrimPopup ? (
        <Popup isOpen={toggleSaveFilterConfrimPopup} onClose={handleToggleSaveFilterClick}>
          <div className="confirm-save-filter-popup">
            <h3>Create New Filter</h3>
            <p></p>
            <input
              type="text"
              name="key"
              value={newFilterName}
              placeholder="Give your filter a name"
              onChange={(e) => handleNewFilterNameInputChange(e)}
              autoComplete="off"
              spellCheck="off"
            />
            <Button text={!isLoadingSaveFilter ? 'Save' : 'Loading'} onClick={()=> handleConfirmSaveFilterClick()} className="default" />
          </div>
        </Popup>
      ) : <></>}
      <h2>Competitors</h2>
      <div className="filters-header">
        <div className="select-filter-container">
          <div className="filter-item">
            <span>Filter Competitors by Competition</span>
            <select onChange={handleCompetitionChange} value={selectedCompetitionId}>
              <option value="" disabled>Select a competition</option>
              {competitionFilters.map((comp) => (
                <option key={comp.competition_id} value={comp.competition_id}>
                  {comp.competition_name}
                </option>
              ))}
            </select>
          </div>
          <div className="filter-item">
            <span>
              Saved Filters {selectedFilterValue && selectedFilterValue.length > 0 ? (
                <div
                  className="delete-filter"
                  onClick={() => onClickDeleteFilter()}
                >
                  (Delete selected filter)
                </div>) : <></>}
              </span>
            <select className="saved-filters" onChange={handleSavedFilterChange} value={selectedFilterValue}>
              <option value="" disabled>Select a filter</option>
              {savedFilters && savedFilters.length > 0 && savedFilters.map((filter, i) => (
                <option key={i} value={filter.filter_query}>
                  {filter.filter_name}
                </option>
              ))}
            </select>
          </div>
          {comparedCompetitors && comparedCompetitors.length > 1 ? (
            <div
              className="compare-confirm"
              onClick={() => onClickCompareSelections()}
              style={hideNonCompared ? {paddingRight: 40, backgroundColor: "#fff", color: "#000", border: "1px solid #ddd"} : {}}
            >
              {hideNonCompared ? (
                <div className="clear-compare" />
              ) : <></>}
              {hideNonCompared ? "Comparing" : "Compare"} {comparedCompetitors.length} Pitches
            </div>
          ) : <></>}
          {hideNonCompared && (!selectedFilterValue || !selectedFilterValue.length === 0 ) ? (
            <div
              className="save-compare-filter"
              onClick={() => handleToggleSaveFilterClick()}
            >
              Save Filter
            </div>
          ) : <></>}
        </div>
      </div>
      
      {selectedCompetitionData ? (
        <div className="selected-competition-details">
          {selectedCompetitionId ? <BackButton path={`/competition/${selectedCompetitionId}`} /> : <></> }
          <br />
          <h1 onClick={() => onClickCompName()}>{selectedCompetitionData.competition_name}</h1>
          <span><b>{formatDate(selectedCompetitionData.competition_start_date)} </b>to <b>{formatDate(selectedCompetitionData.competition_end_date)}</b></span>
          <br />
          <CompetitionStatus startDate={selectedCompetitionData.competition_start_date} endDate={selectedCompetitionData.competition_end_date} deadline={selectedCompetitionData.competition_deadline_date} />
        </div>
      ) : <></>}

      <div className="user-items">
        {selectedCompetitors && selectedCompetitors.length > 0 && selectedCompetitors.map((competitorItem) => {
          const { user_id: userId, email: userEmail } = competitorItem;
          const isCompared = comparedCompetitors.some(compared => compared.user_id === userId);
          const displayStyle = hideNonCompared && !isCompared ? { display: 'none' } : {};

          return (
            <div
              key={userId}
              className="user-item"
              style={displayStyle}
            >
              <div className="user-compare-select-container">
                <div
                  className={`compare-toggle ${isCompared ? 'active' : ''}`}
                  onClick={() => toggleUserCompare(competitorItem)}
                />
                <div className="compare-text">Compare</div>
              </div>
              <UserCardComplex key={userId} competitionId={selectedCompetitionId} userId={userId} userEmail={userEmail} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Compare;
