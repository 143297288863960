import moment from 'moment';

const CompetitionStatus = ({startDate, endDate, deadline}) => {
  if (!startDate || !endDate) return null;

  const now = moment();
  const start = moment(startDate);
  const subDeadline = moment(deadline ? deadline : endDate);
  const end = moment(endDate);

  if (now.isBefore(start)) {
    return <div>The competition has not started yet.</div>;
  } else if (now.isAfter(end)) {
    return <div>The competition has ended.</div>;
  } else if (now.isAfter(subDeadline)) {
    return <div>The competition is ongoing, but the submission deadline has passed.</div>;
  } else {
    const daysRemaining = end.diff(now, 'days');
    const submissionDaysRemaining = subDeadline.diff(now, 'days');
    return (
      <div>
        The competition has started and will end in {daysRemaining} day{daysRemaining > 1 ? "s" : ""}. 
        The submission deadline is in {submissionDaysRemaining} day{submissionDaysRemaining > 1 ? "s" : ""}.
      </div>
    );
  }
};

export default CompetitionStatus;
