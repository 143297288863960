import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './back-button.scss';

const BackButton = ({ steps = 1, path = null }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [historyStack, setHistoryStack] = useState([]);

  useEffect(() => {
    setHistoryStack((prevStack) => [...prevStack, location.pathname]);
  }, [location]);

  const handleBack = () => {
    if (path) {
      navigate(path);
    } else {
      navigate(-steps);
    }
  };

  const canGoBack = path ? true : window.history.length > steps;

  if (!canGoBack) {
    return null;
  }

  return (
    <div className="back-button-container">
      <div className="back-button" onClick={handleBack}>
        <div className="back-title">BACK</div>
      </div>
    </div>
  );
};

export default BackButton;
